
//noinspection SassScssResolvedByNameOnly
.dropdown-menu.bg-dark {
  color: $navbar-dark-color;
  background-color: darken($dark, 5%) !important;
  border: $dropdown-border-width solid $dropdown-border-color;
}

//noinspection SassScssResolvedByNameOnly
.bg-dark {
  .dropdown-divider {
    @include nav-divider($dark);
  }
  .dropdown-item {
    color: $gray-300;

    @include hover-focus {
      color: $gray-100;
      text-decoration: none;
      @include gradient-bg(darken($dark, 10%));
    }

    &.active,
    &:active {
      color: $dropdown-link-active-color;
      text-decoration: none;
      @include gradient-bg($dropdown-link-active-bg);
    }

    &.disabled,
    &:disabled {
      color: $dropdown-link-disabled-color;
      background-color: transparent;
      // Remove CSS gradients if they're enabled
      @if $enable-gradients {
        background-image: none;
      }
    }
  }
  .dropdown-header {
    color: $dropdown-header-color;
  }
  .dropdown-item-text {
    color: $dropdown-link-color;
  }
}

