/**
 * Admin yPunto <info@solucionesypunto.com>
 */

// fontawsome
// ----------------------------------------------------------------------
$fa-font-path: "../font";

// bootstrap
// ----------------------------------------------------------------------
// gray scale
$white: #fff;
$gray-100: #f6f6f5;
$gray-200: #ecebe9;
$gray-300: #d3d1cf;
$gray-400: #a9a6a4;
$gray-500: #817e7a;
$gray-600: #706b6b;
$gray-700: #524f51;
$gray-800: #2e2c2d;
$gray-900: #1e1a1a;
$black: #000;

// colors
$blue: #0b4693;
$indigo: #5E2750;
$purple: #762572;
$pink: #b54d6b;
$red: #ED3146;
$orange: #E95420;
$yellow: #F89B0F;
$green: #3EB34F;
$cyan: #2a8d9b;

// theme colors
$primary: #d2571d;
$secondary: $indigo;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

// agregamos un default en gris ya que secondary tiene color
$theme-colors: (
        "default": $gray-500,
  //"green": $green,
);

// font settings
//$font-family-base: 'Oxygen', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-base: 'Ubuntu', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$headings-font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
//$font-size-base: .95rem;
$headings-font-weight: 400;
$lead-font-weight: inherit;

$small-font-size: 85%;

// general settings
$enable-shadows: true;
$border-radius-base: .1rem;
$border-radius-sm: .54 * $border-radius-base;
$border-radius: $border-radius-base;
$border-radius-lg: 1.33 * $border-radius-base;
$border-color: $gray-200;

// dropdowns
$dropdown-link-hover-bg: $gray-200;

// navbars
$navbar-dark-hover-color: $gray-100;

// alerts
$alert-bg-level: -7;
$alert-border-level: 0;
$alert-color-level: 8;

// tables
$table-border-color: $gray-200;

// forms
$input-border-color: $gray-300;
$input-focus-border-color: $primary;
$input-focus-box-shadow: 0 0 0 .2rem rgba($primary, .5);

// modals
$modal-content-box-shadow-sm-up: 0 .5rem 5rem rgba($black, .5);

// custom components
// sidebar
$sidebar-width: 15rem;

// content
$content-width: 1200px;
$side-content-width: 18rem;
$side-content-width-xl: 21rem;

$card-spacer-y: .5rem;
$card-spacer-x: .75rem;

// establecemos unas bases para transiciones, ahora mismo sólo lo usa sidebar, pero podría mantenerse para otros
// componentes, de esta forma se puede activar o desactivar las animaciones y/o cambiar el timing desde
// un sólo lugar
$transition-tic-base: 100ms;
$transition-tic-step: 3;
$transition-tic: $transition-tic-base * $transition-tic-step;
